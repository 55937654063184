import { module } from 'modujs';
import { gsap } from 'gsap';
import { CUSTOM_EVENT } from '../config';

const duration = 3;
const ease = 'power4.inOut';

export default class extends module {
    constructor(m) {
        super(m);

        this.current = -1;

        this.$images = Array.from(this.$('image'));

        this.$pills = Array.from(this.$('pill'));

        // Group images by pair
        this.diptychs = this.$images.reduce((acc, current, index) => {
            let diptych;
            if(index % 2 == 0) {
                diptych = []
            } else {
                diptych = acc.pop();
            }

            diptych.push(current);

            acc.push(diptych)

            return acc;
        }, new Array())
    }

    init() {
        this.bindEvents();

        this.resize()
        this.setShapesTimeline();
        this.loop();
    }

    bindEvents() {
        window.addEventListener(CUSTOM_EVENT.RESIZE_END, this.resize)
    }

    unbindEvents() {
        window.removeEventListener(CUSTOM_EVENT.RESIZE_END, this.resize)
    }

    setShapesTimeline() {
        this.shapesTl = gsap.timeline({ repeat: -1, defaults: { duration, ease } });

        // this.shapesTl.timeScale(0.25)

        const largePills = this.$pills.filter(pill => pill.classList.contains('-large')).map(el => { return { mask: -1, el }});
        const smallPills = this.$pills.filter(pill => pill.classList.contains('-small')).map(el => { return { mask: -1, el }});

        // const duration = 0.5;

        const updatePillMask = pill => {
            pill.el.style.setProperty('--pill-mask-progress', pill.mask);
        }

        // ############## START
        this.shapesTl.addLabel('start')

        this.shapesTl.set(largePills[0], { mask: 1}, 'start');
        this.shapesTl.add(updatePillMask.bind(this, largePills[0]), 'start');
        this.shapesTl.fromTo(largePills[0].el, { x: '0%' }, { x: '-100vw' }, 'start')

        this.shapesTl.set(largePills[1], { mask: 0 }, 'start')
        this.shapesTl.add(updatePillMask.bind(this, largePills[1]), 'start');
        this.shapesTl.fromTo(largePills[1].el, { x: '100vw' }, { x: '0%' }, 'start')

        this.shapesTl.fromTo(smallPills[0], { mask: 0 }, { mask: 1, onUpdate: updatePillMask.bind(this, smallPills[0])}, 'start')
        this.shapesTl.set(smallPills[1].el, { x: '100vw' }, 'start');

        // ############## HALF
        this.shapesTl.addLabel('half')

        this.shapesTl.to(largePills[1], { mask: 1, onUpdate: updatePillMask.bind(this, largePills[1]) }, 'half')
        this.shapesTl.to(smallPills[0].el, { x: '-100vw' }, 'half');

        this.shapesTl.to(smallPills[1].el, { x: '0vw' }, 'half');

        this.shapesTl.addLabel('end')
        // #################
        this.shapesTl.progress(0.1).progress(0).pause();
    }

    moveShapes() {
        return new Promise(resolve => {
            let nextLabel = this.shapesTl.nextLabel();
            if(!nextLabel) nextLabel = 'half';

            this.shapesTl.tweenFromTo(nextLabel == 'half' ? 'start' : 'half', nextLabel, { onComplete: resolve });
        })
    }

    async loop() {
        if(this.current < this.diptychs.length - 1) this.current++;
        else this.current = 0;
        await Promise.all([
            this.moveShapes(),
            this.showDiptych(this.current)
        ]);
        this.preloadNextDiptych();
        await new Promise(r => setTimeout(r, 3000));
        if(this.toDestroy) return;
        this.loop();
    }

    hideDiptych(index) {
        return new Promise(resolve => {
            if(!this.currentDiptych) return resolve();

            const tl = gsap.timeline({ onComplete: resolve });

            for(let image of this.currentDiptych) {
                image.style.zIndex = 1;

                if(window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
                    tl.to(image.children[0], { opacity: 0, duration, ease }, 0)
                } else {
                    tl.to(image.children[0], { x: '-100%', scale: 1, duration, ease }, 0)
                }


                tl.set(image.children[0], { clearProps: 'all' })
                tl.add(() => image.classList.remove('is-active'));
            }

            tl.play()
        })
    }

    showDiptych(index) {
        return Promise.all([
            this.hideDiptych(),
            new Promise(resolve => {

                this.currentDiptychIndex = index;
                this.currentDiptych = this.diptychs[this.currentDiptychIndex];
                // this.currentDiptychTl = this.timelines.diptychs[this.currentDiptychIndex];

                const tl = gsap.timeline({ onComplete: resolve });

                for(let [index, image] of this.currentDiptych.entries()) {
                    image.classList.add('is-active')
                    image.style.zIndex = 0;

                    if(window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
                        tl.from(image.children[0], { opacity: 0, duration, ease }, 0)
                    } else {
                        tl.from(image.children[0], { x: '50%', scale: 1.25, opacity: 0, duration, ease }, 0)
                    }
                }

                tl.play()
            })
        ]);
    }

    preloadNextDiptych() {
        // Set next diptych index
        let next;
        if(this.current < this.diptychs.length - 1) next = this.current+1;
        else next = 0;

        for(let [index, image] of this.diptychs[next].entries()) {
            // Only load the first image of next diptych on mobiles
            if(window.innerWidth < 700 && index > 0) continue;

            if(!image.classList.contains('preloaded')) {
                fetch(image.querySelector('img').src);
                image.classList.add('preloaded')
            }
        }
    }

    resize = () => {
        for(let pill of this.$pills) {
            const { height, width } = pill.querySelector('span').getBoundingClientRect()
            const size = Math.min(width, height);

            pill.style.setProperty('--pill-radius',`${size/2}px`);
        }
    }

    destroy() {
        super.destroy();

        this.unbindEvents();
        this.toDestroy = true;
    }
}
