import { module } from 'modujs';
import { FONT } from '../config';
import { whenReady } from '../utils/fonts';
import gsap from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);

        this.$titles = [...this.$('title')]
        this.$titleLeft = this.$titles[0]
        this.$titleRight = this.$titles[1]

        this.isMobile = window.matchMedia('(max-width: 768px)').matches
        this.isReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    }

    init() {
        this.bindEvents();

        whenReady(FONT.EAGER).then((fonts) => this.onFontsLoaded(fonts));
    }

    bindEvents() {
        window.addEventListener('resize', this.onResize);
    }

    unbindEvents() {
        window.removeEventListener('resize', this.onResize);
    }

    onFontsLoaded(fonts) {
        this.computeTL();
    }

    destroy() {
        super.destroy();

        this.unbindEvents();

        this.tl?.kill?.();
        this.tl = null;
    }

    onResize = () => {
        this.isMobile = window.matchMedia('(max-width: 768px)').matches;

        this.computeTL();
    }

    computeTL = () => {
        const progress = this.tl?.progress?.() ?? 0;
        this.tl?.kill?.();
        this.tl = null;
        gsap.set(this.$titles, { clearProps: 'all' });

        if (this.isMobile) {
            return;
        }

        if (this.isReducedMotion) {
            gsap.set(this.$titles, { x: 0 });
            return;
        }

        requestAnimationFrame(() => {
            this.tl = gsap.timeline();

            // this.titleLeftBCR = this.$titles[0].getBoundingClientRect();
            // this.resetCoordsLeft = { x: this.titleLeftBCR.x };
            // this.titleRightBCR = this.$titles[1].getBoundingClientRect();
            // this.resetCoordsRight = { x: this.titleRightBCR.x };

            for (let i = 0; i < this.$titles.length; i++) {
                const $title = this.$titles[i];
                this.targetCoords = i === 0 ? this.targetCoordsLeft : this.targetCoordsRight;

                this.tl.fromTo($title, {
                    xPercent: i === 0 ? 200 : -200,
                    scale: 0.25,
                }, {
                    xPercent: 0,
                    scale: 1,
                    duration: 0.75 + (i * 0.25),
                    clearProps: 'all',
                }, 0);
            }

            this.tl.progress(progress);
            this.tl.pause();
        });
    }

    onScrollProgress(value) {
        this.tl?.progress?.(value);
    }
}
