import { module } from 'modujs';

const reducedMotionMedia = window.matchMedia("(prefers-reduced-motion: reduce)");

export default class extends module {
    constructor(m) {
        super(m);

        this.$video = this.el.querySelector('video')
        this.$video.muted = true

        this.$toggler = this.$('toggler')[0];

        this.isMobile = window.matchMedia('(max-width: 999px)').matches;
    }

    init() {
        this.bindEvents();
    }

    bindEvents() {
        reducedMotionMedia.addEventListener('change', this.onMediaChange)
    }

    unbindEvents() {
        reducedMotionMedia.removeEventListener('change', this.onMediaChange)
    }

    onMediaChange = () => {
        try {
            if(this.el.classList.contains('is-inview')) {
                if(reducedMotionMedia.matches) {
                    this.$video.pause();
                } else {
                    this.$video.play();
                }
            }
        } catch(e) {
            console.error(e);
        }
    }

    onInview(e) {
        if(e.target != this.el) return;

        const attrPoster = this.isMobile ? 'data-video-modal-mobile-poster' : 'data-video-modal-poster';
        const poster = this.$toggler.getAttribute(attrPoster);
        
        if (poster) {
            const img = new Image();
            img.src = poster;
        }

        try {
            if(e.way == 'enter' && !reducedMotionMedia.matches) {
                this.$video.play()
            } else {
                this.$video.pause();
            }
        } catch(e) {
            console.error(e);
        }
    }

    destroy() {
        super.destroy();

        this.unbindEvents();
    }
}
