// Props to https://css-tricks.com/how-to-animate-the-details-element-using-waapi/

import { module as Module } from 'modujs';

export default class Accordion extends Module {
    static CLASS_ACTIVE = 'is-active';
    static EASING = 'cubic-bezier(0.33, 1, 0.68, 1)';
    static DURATION = 300;

    constructor(m) {
        super(m);

        // Binding
        this.onClickBind = this.onClick.bind(this);

        // UI
        this.$summary = this.$('summary')[0];
        this.$content = this.$('content')[0];
        this.$parent = this.el.closest('[data-accordion-parent]') || null;

        // Data
        this.animation = null;
        this.isClosing = false;
        this.isExpanding = false;
    }

    ///////////////
    // Lifecyle
    ///////////////
    init() {
        this.bindEvents();
    }

    destroy() {
        super.destroy();

        this.unbindEvents();
    }

    ///////////////
    // Events
    ///////////////
    bindEvents() {
        this.$summary.addEventListener('click', this.onClickBind);
    }

    unbindEvents() {
        this.$summary.removeEventListener('click', this.onClickBind);
    }

    ///////////////
    // Callbacks
    ///////////////
    onClick(e) {
        e.preventDefault();

        this.el.style.overflow = 'hidden';

        if (this.isClosing || !this.el.open) {
            this.open();
        } else if (this.isExpanding || this.el.open) {
            this.shrink();
        }
    }

    onAnimationFinish(open) {
        this.el.open = open;

        this.animation = null;

        this.isClosing = false;
        this.isExpanding = false;

        this.el.style.height = this.el.style.overflow = '';
    }

    ///////////////
    // Methods
    ///////////////
    shrink() {
        this.isClosing = true;
        this.el.classList.remove(Accordion.CLASS_ACTIVE);

        if (this.$parent) this.$parent.classList.remove(Accordion.CLASS_ACTIVE);

        const startHeight = `${this.el.offsetHeight}px`;
        const endHeight = `${this.$summary.offsetHeight}px`;

        if (this.animation) {
            this.animation.cancel();
        }

        this.animation = this.el.animate(
            {
                height: [startHeight, endHeight]
            },
            {
                duration: Accordion.DURATION,
                easing: Accordion.EASING
            }
        );

        this.animation.onfinish = () => this.onAnimationFinish(false);
        this.animation.oncancel = () => {
            this.isClosing = false;
            this.el.classList.add(Accordion.CLASS_ACTIVE);
        };

        this.onShrink?.(this.el);
    }

    open() {
        this.el.style.height = `${this.el.offsetHeight}px`;
        this.el.open = true;

        window.requestAnimationFrame(() => this.expand());

        this.onOpen?.(this.el);
    }

    expand() {
        this.isExpanding = true;
        this.el.classList.add(Accordion.CLASS_ACTIVE);

        if (this.$parent) this.$parent.classList.add(Accordion.CLASS_ACTIVE);

        const startHeight = `${this.el.offsetHeight}px`;
        const endHeight = `${this.$summary.offsetHeight + this.$content.offsetHeight}px`;

        if (this.animation) {
            this.animation.cancel();
        }

        this.animation = this.el.animate(
            {
                height: [startHeight, endHeight]
            },
            {
                duration: Accordion.DURATION,
                easing: Accordion.EASING
            }
        );

        this.animation.onfinish = () => this.onAnimationFinish(true);
        this.animation.oncancel = () => {
            this.isExpanding = false;
            this.el.classList.remove(Accordion.CLASS_ACTIVE);
        };
    }
}
