import { module } from 'modujs';
import { gsap, SplitText } from 'gsap/all';
import { CUSTOM_EVENT } from '../config';

gsap.registerPlugin(SplitText);

export default class extends module {
    constructor(m) {
        super(m);

        this.$container = this.el;
        this.$next = this.$('next')[0];
        this.$questions = [...this.$('question')];
        this.$counter = this.$('counter')[0];
        this.$correct = this.$('correct')[0];
        this.$explanationEl = this.$('explanation')[0];
        this.$explain = this.$('explain')[0];
        this.$result = this.$('result')[0];

        this.length = Number(this.$container.dataset.quizLength);
        this.currentQuestionIndex = 0;
        this.correctAnswers = 0;
        this.splitTexts = [];

        this.reducedMotionMedia = window.matchMedia("(prefers-reduced-motion: reduce)")

        this.animateQuestion = this.withMotionCheck(this.animateQuestion);
        this.animateAnswer = this.withMotionCheck(this.animateAnswer);
        this.animateFinish = this.withMotionCheck(this.animateFinish);
        this.splitLines = this.withMotionCheck(this.splitLines);
    }

    init() {
        this.bindEvents();
        this.splitLines();

        this.loadQuestion(this.currentQuestionIndex);
    }

    destroy() {
        super.destroy();

        this.unbindEvents();
        this.killSplit();
    }

    /**
     * Bind events for answers and the next button.
     */
    bindEvents() {
        this.$next.addEventListener('click', this.handleNextClick);

        this.reducedMotionMedia?.addEventListener("change", this.onMediaChange)

        window.addEventListener(CUSTOM_EVENT.RESIZE_END, this.onResize);
    }

    unbindEvents() {
        this.$next.removeEventListener('click', this.handleNextClick);

        this.reducedMotionMedia?.removeEventListener("change", this.onMediaChange)

        window.removeEventListener(CUSTOM_EVENT.RESIZE_END, this.onResize);
    }

    onMediaChange = () => {
        this.isReducedMotion = this.reducedMotionMedia.matches;

        if (this.isReducedMotion) {
            this.killSplit();
        } else {
            this.splitLines();
        }
    }

    withMotionCheck(fn) {
        return (...args) => {
            if (this.isReducedMotion) return;
            fn(...args);
        };
    }

    /**
     * Load a specific question.
     * @param {number} i - The index of the question to load.
     */
    loadQuestion(i) {
        this.animateQuestionCounter(i);

        this.$questions[i].classList.add('is-active');
        this.$questions[i].querySelectorAll('.c-quiz_answer').forEach((answer) => {
            answer.addEventListener('click', (e) => this.handleAnswerClick(e));
        });
    }

    /**
     * Animate the question counter.
     * @param {number} idx - The current question index.
    */
    animateQuestionCounter(idx) {
        const tl = gsap.timeline();
        tl
            .fromTo(this.$counter, {
                yPercent: 0,
                opacity: 1,
            }, {
                yPercent: -50,
                opacity: 0,
                duration: 0.3,
                ease: 'quart.inOut',
                onComplete: () => {
                    this.$counter.textContent = idx + 1;
                },
            })
            .fromTo(
                this.$counter,
                {
                    yPercent: 50,
                    opacity: 0,
                },
                {
                    yPercent: 0,
                    opacity: 1,
                    duration: 0.4,
                    ease: 'quart.out',
                }
            );
    }

    /**
     * Handle answer selection.
     * @param {Event} event - The click event on an answer.
     */
    handleAnswerClick(event) {
        const answer = event.target;
        if (!answer.matches('.c-quiz_answer')) return;

        this.$questions.forEach((question) => question.classList.remove('is-active'));
        this.$questions[this.currentQuestionIndex].querySelectorAll('.c-quiz_answer').forEach((answer) => {
            answer.removeEventListener('click', (e) => this.handleAnswerClick(e));
        });

        const isCorrect = answer.dataset.correct === 'true';
        answer.classList.add(isCorrect ? 'is-correct' : 'is-wrong');
        this.correctAnswers += isCorrect ? 1 : 0;

        this.$correct.innerText = isCorrect ? 'Correct!' : 'Wrong!';

        // Show explanation
        this.$explanationEl.innerText = this.$questions[this.currentQuestionIndex].dataset.quizExplanation;

        // Show next button
        if (this.currentQuestionIndex === this.length - 1) {
            this.$next.innerHTML = '<span class="c-quiz_next-label">Finish the quiz</span>';
            this.$next.dataset.text = 'Finish the quiz';
        }

        this.$explain.classList.add('is-active');

        this.animateAnswer(this.$explanationEl, this.$correct);
        this.$correct.focus();
    }

    /**
     * Handle clicking the next button.
     */
    handleNextClick = () => {
        this.currentQuestionIndex++;
        this.$explain.classList.remove('is-active');

        if (this.currentQuestionIndex < this.length) {
            this.loadQuestion(this.currentQuestionIndex);
            this.animateQuestion();

            // Double RAF seems required for focus to work, may be related to the animation and/or the text split
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    this.$questions[this.currentQuestionIndex].querySelector('h4').focus()
                })
            })
        } else {
            this.$result.classList.add('is-active');

            if (this.correctAnswers >= this.length - 1) {
                this.$result.children[0].innerHTML = `
                    <h4 class="c-text -body-lg" tabindex="-1" data-quiz="correct">10s ACROSS</h4>
                    <p class="c-quiz_explanation || c-text -body-sm u-margin-top-xs">You’re a prevention powerhouse! Your expertise is paving the way for safer, stronger communities. Keep shining and leading by example.</p>
                `;
            } else {
                this.$result.children[0].innerHTML = `
                    <h4 class="c-text -body-lg" tabindex="-1" data-quiz="correct">IT'S A CHOP</h4>
                    <p class="c-quiz_explanation || c-text -body-sm u-margin-top-xs">Not a perfect score, but every step forward counts. Revisit the facts, regroup, and get back in the game—safer sex is everyone’s priority.</p>
                `;
            }

            this.animateFinish(this.$result.children[0]);
            this.$result.querySelector('h4').focus()
        }
    }

    onResize = () => {
        this.killSplit();
        this.splitLines();

        const explanationText = this.$explanationEl;

        if (explanationText) {
            const splitExplanation = new SplitText(explanationText, { type: 'lines', linesClass: 'u-lines-split' });
            for(let line of splitExplanation.lines) line.setAttribute('aria-hidden', 'true');
            this.splitTexts.push({ element: explanationText, split: splitExplanation });
        }
    };

    splitLines = () => {
        this.splitTexts?.forEach((split) => split?.revert());
        this.splitTexts = [];

        this.$questions.forEach((question) => {
            const title = question.querySelector('.c-quiz_question-item');
            const split = new SplitText(title, { type: 'lines', linesClass: 'u-lines-split' });
            for(let line of split.lines) line.setAttribute('aria-hidden', 'true')
            gsap.set(split.lines, { yPercent: 75, opacity: 0 });
            this.splitTexts.push({ element: title, split });
        });

        this.animateQuestion();
    }

    animateQuestion = () => {
        if (!this.$questions[this.currentQuestionIndex]) return;
        const split = this.splitTexts.find(({ element }) => element === this.$questions[this.currentQuestionIndex].querySelector('.c-quiz_question-item'))?.split;
        if (!split) return;

        gsap.to(split.lines, {
            yPercent: 0,
            opacity: 1,
            stagger: 0.1,
            duration: 0.75,
            ease: 'quart.out',
            clearProps: 'all',
        });
    }

    animateAnswer = (el, correctEl) => {
        el.setAttribute('aria-label',el.innerText);
        const splitAnswer = new SplitText(el, { type: 'lines', linesClass: 'u-lines-split' });
        for(let line of splitAnswer.lines) line.setAttribute('aria-hidden', 'true');

        const tl = gsap.timeline();
        tl
            .fromTo(splitAnswer.lines, {
                yPercent: 100,
                clipPath: 'polygon(0% 110%, 100% 110%, 100% 210%, 0% 210%)',
            }, {
                yPercent: 0,
                clipPath: 'polygon(0% -10%, 100% -10%, 100% 110%, 0% 110%)',
                stagger: 0.1,
                duration: 0.75,
                ease: 'quart.out',
                clearProps: 'all',
            }, 0)
            .from(correctEl, {
                opacity: 0,
                duration: 1,
                ease: 'quart.out',
                clearProps: 'all',
            }, 0.2);

        this.splitTexts.push({ element: el, split: splitAnswer });
    }

    animateFinish = (el) => {
        gsap.from(el, {
            scale: 0.9,
            opacity: 0,
            duration: 1,
            ease: 'quart.out',
            clearProps: 'all',
        });
    }

    killSplit() {
        this.splitTexts.forEach(({ split }) => split.revert());
        this.splitTexts = [];
    }
}
