import gsap from "gsap";
import { Flip } from 'gsap/Flip';

gsap.registerPlugin(Flip);

export default function () {
    return new Promise(resolve => {
        if(window.matchMedia('(prefers-reduced-motion: reduce)').matches || localStorage.getItem('you=us_intro-played') == 'true') {
            resolve();
            return;
        }

        localStorage.setItem('you=us_intro-played', true);

        $spinner = document.getElementById('preloader-spinner');
        $preloaderLogo = document.getElementById('preloader-logo');
        $siteLogo = document.getElementById('desktop-logo');

        const tl = gsap.timeline({ defaults: { force3D: true }, onComplete: () => {
            resolve();
        } });

        tl.to($spinner, { opacity: 0 })
        tl.set($preloaderLogo, { opacity: 1 })

        const start = document.getElementById('preloader-start')
        const uEqualsU = document.getElementById('preloader-u=u')
        const end = document.getElementById('preloader-end')

        tl.set(uEqualsU.children[3], { transformOrigin: 'center right', x: '100%' });

        tl.from([uEqualsU.children[0],uEqualsU.children[1]], { y: '-110%', stagger: 0.1, duration: 0.5 })
        tl.fromTo(uEqualsU.children[2], { scaleX: 0 }, { scaleX: 1 }, 0.6)
        tl.fromTo(uEqualsU.children[3], { scaleX: 0 }, { scaleX: 1 }, 0.6)

        tl.addLabel('sides', 1.5);

        tl.from(uEqualsU, { x: '-8%', duration: 1, ease: 'power2.out' }, 'sides')
        tl.from(start, { x: '-100%', duration: 1, ease: 'power2.out' }, 'sides')
        tl.from(end, { x: '100%', duration: 1, ease: 'power2.out' }, 'sides')

        if(window.matchMedia('(min-width: 1000px)').matches) {
            tl.add(() => {
                const state = Flip.getState([this.$preloaderLogo]);

                Flip.fit(this.$preloaderLogo, $siteLogo, {
                    // scale: true
                })

                Flip.from(state, {
                    duration: .5,
                    // scale: true,
                    ease: 'power2.inOut'
                })
            })

            tl.add(() => {}, '+=.5')
        }
    })
}
