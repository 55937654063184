import Modal from './Modal';

export default class extends Modal {
    constructor(m) {
        super(m);

        this.focusTrapOptions.checkCanReturnFocus = this.checkCanReturnFocus;

        this.$copy = this.el.querySelector('[data-share-modal-copy] .c-button_label')

        this.events = {
            click: {
                share: 'share',
                close: 'close'
            },
        };
    }

    share(event) {
        const targetElement = event.curTarget;
        const shareMethod   = this.getData('method', targetElement)
        const shareURL      = window.location.href

        window.dataLayer?.push({
            event: "share",
            method: shareMethod
        })

        switch (shareMethod) {
            case 'facebook': {
                const platformURL = `https://facebook.com/sharer/sharer.php?u=${shareURL}`;
                this.openWindow(platformURL);
                break;
            }

            case 'X': {
                const text = encodeURIComponent(this.getData('text', targetElement));
                const platformURL = `https://twitter.com/share?url=${shareURL}&amp;text=${text}`;
                this.openWindow(platformURL);
                break;
            }

            case 'email': {
                const subject = encodeURIComponent(this.getData('text', targetElement));
                const body    = encodeURIComponent(this.getData('body', targetElement));
                this.openMail(subject, `${body} ${shareURL}`);
                break;
            }

            case 'LinkedIn': {
                const encodedURL  = encodeURIComponent(shareURL);
                const platformURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedURL}`;
                this.openWindow(platformURL);
                break;
            }

            case 'link': {
                this.copyUrl(shareURL);
                break;
            }
        }
    }

    openWindow(url) {
        window.open(url, 'Share', 'menubar=no, toolbar=no, resizable=yes, scrollbars=yes, height=500, width=600');
    }

    openMail(subject, body) {
        window.location = 'mailto:?subject=' + subject + '&body=' + body;
    }

    copyUrl(url) {
        if (this.copyTimeout != undefined) {
            clearTimeout(this.copyTimeout);
        }

        this.$copy.textContent = this.getData('copy-success');

        this.copyTimeout = setTimeout(() => {
            this.$copy.textContent = this.getData('copy-label');
        }, 1500);

        navigator.clipboard.writeText(url);
    }

    onOpen() {
        this.openDialogOnClose = document.querySelector('[data-module-dialog][open]');
        if(this.openDialogOnClose) {
            this.call('close', null, 'Dialog');
        }
    }

    checkCanReturnFocus = (trigger) => {
        return new Promise(resolve => {
            const dialog = document.querySelector('[data-module-dialog]');

            if(dialog && dialog.contains(trigger)) {
                const duration = parseFloat(getComputedStyle(trigger).getPropertyValue('--dialog-animation-duration'))*1000;
                console.log(duration);

                setTimeout(resolve, duration);
            } else {
                resolve();
            }
        })
    }

    onDeactivate() {
        if(this.openDialogOnClose) {
            this.call('show', { preventScrollTop: true }, 'Dialog');
            this.openDialogOnClose = undefined;
        }
    }
}
