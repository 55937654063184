import { gsap } from 'gsap';
import { SplitText } from 'gsap/all';
import SubjectAnimation from './SubjectAnimation';

gsap.registerPlugin(SplitText)

export const titleDest = {
    y: '33lvh',
    ease: 'linear'
}

export default class extends SubjectAnimation {
    constructor(m) {
        super(m);

        this.$title = this.$('title')[0];
        this.$titleInner = this.$('title-inner')[0];
    }

    setTimeline() {
        this.tl?.revert?.();
        this.split?.revert?.();
        this.tl = null;

        if(this.reducedMotionMedia.matches) return;

        this.split = new SplitText(this.$titleInner, { type: 'chars' })

        this.tl = gsap.timeline({ defaults: { duration: 1, ease: 'power3.inOut' }})

        this.tl.fromTo(this.$title, { y: 0 }, titleDest);

        this.tl.progress(0.1).progress(0).pause();
    }

    destroy() {
        super.destroy();

        this.split?.revert?.();
    }
}
