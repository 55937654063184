import { module } from 'modujs';
import { gsap, MorphSVGPlugin } from 'gsap/all';

gsap.registerPlugin(MorphSVGPlugin);

export default class extends module {
    constructor(m) {
        super(m);

        this.$path = this.$('path')[0];

        this.startPath = this.$path.getAttribute('d');
        this.endPath = this.getData('end-path');
        this.animateOnScroll = this.getData('animate-on-scroll') === 'true';

        this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        this.reducedMotionMedia = window.matchMedia('(prefers-reduced-motion: reduce)');
        this.isReducedMotion = this.reducedMotionMedia.matches;

        this.events = {
            mouseenter: {
                btn: 'onMouseEnter',
            }, 
            mouseleave: {
                btn: 'onMouseLeave',
            },
            focus: {
                btn: 'onMouseEnter',
            },
            blur: {
                btn: 'onMouseLeave',
            }
        };

        this.onScroll = this.withMotionCheck(this.onScroll);
    }

    withMotionCheck(fn) {
        return (...args) => {
            if (this.isReducedMotion) return;
            fn(...args);
        };
    }

    init() {
        this.bindEvents();

        if (this.animateOnScroll && !this.isSafari && !this.isReducedMotion) {
            this.onScroll();
        }
    }

    destroy() {
        super.destroy();

        this.unbindEvents();

        gsap.set(this.$path, { clearProps: 'all' });
    }

    bindEvents() {
        this.reducedMotionMedia?.addEventListener('change', this.onMediaChange);
    }

    unbindEvents() {
        this.reducedMotionMedia?.removeEventListener('change', this.onMediaChange);
    }

    onMediaChange = () => {
        this.isReducedMotion = this.reducedMotionMedia.matches;

        if (this.isReducedMotion) {
            gsap.set(this.$path, { morphSVG: this.startPath });
            this.tl?.kill?.();
            this.tl = null;
        } else {
            this.onScroll();
        }
    };

    onMouseEnter = () => {
        if (this.isReducedMotion) {
            gsap.set(this.$path, { morphSVG: this.endPath });
            return;
        }

        gsap.to(this.$path, { duration: 0.5, morphSVG: this.endPath, ease: 'quart.out' });
    }

    onMouseLeave = () => {
        if (this.isReducedMotion) {
            gsap.set(this.$path, { morphSVG: this.startPath });
            return;
        }

        gsap.to(this.$path, { duration: 0.5, morphSVG: this.startPath, ease: 'quart.out' });
    }

    onScroll = () => {
        const progress = this.tl?.progress?.() ?? 0;
        this.tl?.kill?.();
        this.tl = null;

        this.tl = gsap.timeline();

        this.tl.from(this.$path, {
            morphSVG: this.endPath,
        });

        this.tl.progress(progress);
        this.tl.pause();
    }

    onScrollProgress(value) {
        this.tl?.progress?.(value);
    }
}
