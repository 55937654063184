import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            focusin: 'onFocus'
        }

        this.$clippedContainers = [this.el.querySelector('[data-module-subject-head]'), this.el.querySelector('[data-module-subject-video]')];
    }

    onFocus(e) {
        // If focused element is in an active clip-path danger area, force the scroll to safe viewing coordinates.
        if(window.matchMedia("(prefers-reduced-motion: no-preference)").matches && window.matchMedia('(min-width: $from-md)'.matches)) {
            for(let clippedContainer of this.$clippedContainers) {
                if(clippedContainer.contains(e.target)) {
                    this.call('scrollTo', {
                        target: this.el.offsetTop+window.innerHeight*2,
                        immediate: true
                    }, 'Scroll')
                    break;
                }
            }
        }
    }
}
