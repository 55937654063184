import Modal from "./Modal";

export default class extends Modal {
    constructor(m) {
        super(m);

        this.$video = this.el.querySelector('video')

        this.isMobile = window.matchMedia('(max-width: 999px)').matches;
    }

    bindEvents() {
        super.bindEvents();

        this.$video?.addEventListener('ended', this.onVideoEnd)
    }
    unbindEvents() {
        super.unbindEvents();

        this.$video?.removeEventListener('ended', this.onVideoEnd)
    }

    onOpen(e) {
        const { target } = e;

        this.desktopPoster = this.getData('poster', target);
        this.mobilePoster = this.getData('mobile-poster', target);
        this.poster = this.isMobile ? this.mobilePoster : this.desktopPoster
        this.$video.poster = this.poster;

        this.desktopSrc = this.getData('src', target);
        this.mobileSrc = this.getData('mobile-src', target);
        const src = this.isMobile ? this.mobileSrc : this.desktopSrc

        this.$video.src = src;
        this.$video.currentTime = 0;
        this.$video.play();
    }

    onDeactivate() {
        this.$video.pause();
    }

    onVideoEnd = () => {
        this.close();
    }
}
