import { gsap } from 'gsap';
import SubjectAnimation from './SubjectAnimation';

export default class extends SubjectAnimation {
    constructor(m) {
        super(m);

        this.$overlay = this.$('overlay')[0]
        this.$image = this.$('image')[0]
    }

    setTimeline() {
        let progress = this.tl?.progress?.() ?? 0;
        this.tl?.revert?.();
        this.tl = null;

        this.tl = gsap.timeline({ defaults: { duration: 1 }})

        this.tl.set([this.$overlay, this.$image], { clearProps: 'all' }, 'start');

        this.tl.addLabel('start', this.reducedMotionMedia.matches ? 0 : 1)

        this.tl.fromTo(this.$overlay, { opacity: 1 }, { opacity: 0.4, ease: 'power1.in' }, 'start')

        if(!this.reducedMotionMedia.matches) {
            this.tl.from(this.$image, {
                rotate: "6deg",
                duration: 3,
                ease: 'power2.out'
            }, 'start')

            this.tl.from(this.$image, {
                scale: 1.2,
                duration: 3,
                ease: 'power2.out'
            }, 'start')
        }

        this.tl.addLabel('fullyInview', this.reducedMotionMedia.matches ? 1 : 2)

        this.tl.addLabel('fullyHidden', this.reducedMotionMedia.matches ? 2 : 4);
        this.tl.add(() => {}, 'fullyHidden');

        this.tl.progress(0.1).progress(progress).pause();
    }
}
