import { gsap } from 'gsap';
import SubjectAnimation from './SubjectAnimation';

export default class extends SubjectAnimation {
    constructor(m) {
        super(m);

        this.$video = this.$('video')[0];
    }

    setTimeline() {
        this.tl?.revert?.();
        this.tl = null;

        gsap.set(this.$video, { clearProps: 'all' });

        if(this.reducedMotionMedia.matches) return;

        this.tl = gsap.timeline({ defaults: { duration: 1, ease: 'power3.inOut' }})

        const mask = { mask: 1 }

        this.tl.add(() => {
            mask.mask = 1;
            this.$video.style.clipPath = `inset(0 ${50*mask.mask}% 0 ${50*mask.mask}%)`;
        }, 0)

        this.tl.addLabel('fullyInview', 1)

        this.tl.to(mask, {
            mask: 0,
            ease: 'power2.out',
            duration: 0.8,
            onUpdate: () => {
                this.$video.style.clipPath = `inset(0 ${50*mask.mask}% 0 ${50*mask.mask}%)`;
            }
        }, 'fullyInview-=0.33')

        this.tl.to(this.$video, {
            scale: 1.5,
            force3D: true,
            duration: 2,
            ease: 'power1.inOut'
        }, 'fullyInview+=1')

        this.tl.addLabel('fullyHidden', 4);
        this.tl.add(() => {}, 'fullyHidden');


        this.tl.progress(0.1).progress(0).pause();
    }
}
