import { gsap } from 'gsap';
import { SplitText } from 'gsap/all';
import SubjectAnimation from './SubjectAnimation';
import { titleDest } from './SubjectTitleClone';

gsap.registerPlugin(SplitText)

function orderCharsFromCenter(chars) {
    const middleIndex = Math.floor(chars.length / 2); // Calculate the middle index
    const result = [];

    let left = middleIndex; // Start at the middle
    let right = middleIndex + 1; // Start just after the middle for odd splits

    while (left >= 0 || right < chars.length) {
        if (left >= 0) {
            result.push(chars[left]); // Add the character from the left
            left--; // Move left pointer one step to the left
        }
        if (right < chars.length) {
            result.push(chars[right]); // Add the character from the right
            right++; // Move right pointer one step to the right
        }
    }

    return result;
}

export default class extends SubjectAnimation {
    constructor(m) {
        super(m);

        this.$title = this.$('title')[0];
        this.$titleInner = this.$('title-inner')[0];

        this.$authorHead = this.$('author-head')[0];
        this.$authorExcerpt = this.$('author-excerpt')[0];

        this.mobileMedia = window.matchMedia("(min-width: 1000px)")
    }

    bindEvents() {
        super.bindEvents();

        this.mobileMedia?.addEventListener("change", this.onMediaChange)
    }

    unbindEvents() {
        this.mobileMedia?.removeEventListener("change", this.onMediaChange)
    }

    setTimeline() {
        this.tl?.revert?.();
        this.split?.revert?.();
        this.tl = null;

        this.$title.style.removeProperty('--tips-progress');

        if(this.reducedMotionMedia.matches) return;

        const tips = { progress: 0 };
        this.split = new SplitText(this.$titleInner, { type: 'chars' })

        const duration = 1;
        this.tl = gsap.timeline({ defaults: { duration, ease: 'power3.inOut' }})

        this.tl.add(() => { tips.progress = 0 }, 0);

        this.tl.addLabel('getsInview', 1)

        const stagger = 0.025
        this.tl.fromTo(orderCharsFromCenter(this.split.chars), { y: '100%' }, { y: '0%', stagger, duration: duration - (stagger * this.split.chars.length) }, 'getsInview+=0.25')
        this.tl.from(tips, { progress: 1, duration: duration * 0.7, onUpdate: () => {
            this.$title.style.setProperty('--tips-progress', tips.progress);
        } }, 'getsInview+=0.25')

        this.tl.fromTo([this.$authorHead, this.$authorExcerpt], { opacity: 0, y: '10px' }, { opacity: 1, y: 0 }, 'getsInview+=0.5');

        this.tl.addLabel('fullyInview', 2)

        this.tl.addLabel('startDisappear', 3)

        // this.tl.set(this.$title, { color: 'red' }, 'startDisappear');

        if(window.innerWidth >= 1000) {
            this.tl.fromTo(this.$title, { y: 0 }, titleDest, 'startDisappear');
        }

        this.tl.addLabel('fullyHidden', 4);
        this.tl.add(() => {}, 'fullyHidden');


        this.tl.progress(0.1).progress(0).pause();
    }

    destroy() {
        super.destroy();

        this.split?.revert?.();
    }
}
