import { module } from 'modujs';
import { whenReady } from "../utils/fonts.js";
import { gsap, SplitText, ScrollTrigger } from 'gsap/all';
import { CUSTOM_EVENT, FONT } from '../config.js';

gsap.registerPlugin(SplitText, ScrollTrigger)

export default class extends module {
    constructor(m) {
        super(m);

        // Options
        this.revealLines = this.getData('reveal-lines') === 'true';
        this.delay = parseFloat(this.getData('delay')) || 0.0;
        this.stagger = parseFloat(this.getData('stagger')) || 0.02;
    }

    init() {
        this.bindEvents()

        this.el.setAttribute('aria-label', this.el.innerText);

        whenReady(FONT.EAGER).then((fonts) => this.onFontsLoaded(fonts));
    }

    destroy() {
        super.destroy()

        this.clear()
        this.unbindEvents()
    }

    bindEvents() {
        window.addEventListener(CUSTOM_EVENT.RESIZE_END, this.onResize);
    }

    unbindEvents() {
        window.removeEventListener(CUSTOM_EVENT.RESIZE_END, this.onResize);
    }

    ///////////////
    // Callbacks
    ///////////////
    onResize = () => {
       this.reSplit()
    }

    onFontsLoaded(fonts) {
        this.reSplit()
    }

    ///////////////
    // Methods
    ///////////////
    onInView(args) {
        if (args.way == 'leave') {
            this.clear()
        }
    }

    split() {
        this.splitObject = new SplitText(this.el, { type: "lines", linesClass: "u-lines-split" });
        this.splitObject.lines.forEach((line, i) => {
            line.style.setProperty('--line-index', i)
            line.setAttribute('aria-hidden', 'true')
        });

        if (this.revealLines) {
            // Maybe a better way to do this with CSS progress
            this.tlScroll = gsap.timeline({
                scrollTrigger: {
                    trigger: this.el,
                    start: "top bottom-=25%",
                    end: "bottom center",
                    scrub: 1
                }
            })

            for (let i = 0; i < this.splitObject.lines.length; i++) {
                this.tlScroll.fromTo(this.splitObject.lines[i], { opacity: 0.25 }, { opacity: 1 })
            }
        }
    }

    reSplit() {
        this.splitObject?.revert?.();

        requestAnimationFrame(() => {
            this.split();
        })
    }

    clear() {
        this.splitObject?.lines?.forEach((line) => line.style.removeProperty('--line-index'));
        this.splitObject?.revert?.();
        this.splitObject = null;

        this.tlScroll?.kill?.();
        this.tlScroll = null;
    }
}
