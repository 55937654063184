import { module } from 'modujs';
import { gsap, MotionPathPlugin, ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);

export default class extends module {
    constructor(m) {
        super(m);

        this.$testimonial = this.$('testimonial')[0];
        this.$container = this.$('container')[0];
        this.$form = this.$('form')[0];
        this.$number = this.$('number')[0];
        this.$feedback = this.$('feedback')[0];

        this.isMobile = window.matchMedia('(max-width: 768px)').matches;
        this.isReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

        this.tl = [];
    }

    bindEvents() {
        window.addEventListener('resize', this.onResize);
        this.$form.addEventListener('submit', this.onSubmit);
    }

    unbindEvents() {
        window.removeEventListener('resize', this.onResize);
        this.$form.removeEventListener('submit', this.onSubmit);
    }

    init() {
        this.initList();

        this.bindEvents();

        this.onScroll();
    }

    destroy () {
        super.destroy();

        this.unbindEvents();
        this.destroyTL();
    }

    onResize = () => {
        this.isMobile = window.matchMedia('(max-width: 768px)').matches;
    }

    getRandomDate() {
        const launchDate = new Date(2024, 10, 28);
        const today = new Date();

        const randomTime = Math.random() * (today.getTime() - launchDate.getTime());
        return new Date(launchDate.getTime() + randomTime).toLocaleDateString();
    }

    getRandomItem(array) {
        const selectedItem = array[Math.floor(Math.random() * array.length)];
        array.splice(array.indexOf(selectedItem), 1);
        return selectedItem;
    }

    initList () {
        // Banque de noms variés
        this.nameBank = [
            'Abel', 'Adélaïde', 'Aidan', 'Aisha', 'Aliyah', 'Alina', 'Amara',
            'Amira', 'Anika', 'Anwar', 'Aria', 'Aya', 'Ayla', 'Carlos',
            'Chloe', 'Dario', 'Diana', 'Dmitri', 'Eli', 'Elena', 'Eloise',
            'Elio', 'Emil', 'Emily', 'Emma', 'Eshan', 'Esme', 'Eva',
            'Fatima', 'Felix', 'Finn', 'Freya', 'Gabriel', 'Hana', 'Haruto',
            'Hassan', 'Ibrahim', 'Ines', 'Isla', 'Jasmine', 'Jean-Baptiste',
            'Jin', 'Jorge', 'Kamala', 'Kara', 'Kaito', 'Kiran', 'Koji',
            'Kofi', 'Kwame', 'Layla', 'Leila', 'Leona', 'Leo', 'Liam',
            'Luca', 'Lucia', 'Maya', 'Mateo', 'Maia', 'Malik', 'Milo',
            'Miguel', 'Mohamed', 'Nadia', 'Nico', 'Nina', 'Noah', 'Oliver',
            'Olivier', 'Omar', 'Raj', 'Ravi', 'Rhea', 'Rina', 'Rosa',
            'Samir', 'Sara', 'Selene', 'Sienna', 'Sophia', 'Sofia', 'Talia',
            'Tariq', 'Tiana', 'Thiago', 'Tobias', 'Vera', 'Yara', 'Yasmine',
            'Yuki', 'Yuri', 'Zain', 'Zara'
        ];

        // Banque de lieux fixes
        this.locationBank = [
            'Toronto, ON', 'Montreal, QC', 'Vancouver, BC', 'Calgary, AB',
            'Ottawa, ON', 'Edmonton, AB', 'Quebec City, QC', 'Halifax, NS',
            'Winnipeg, MB', 'Victoria, BC', 'Saskatoon, SK', "St. John's, NL",
            'Regina, SK', 'Charlottetown, PE', 'Fredericton, NB', 'Yellowknife, NT',
            'Whitehorse, YT', 'Iqaluit, NU'
        ];

        this.testimonials = Array.from({ length: 10 }, () => ({
            date: this.getRandomDate(),
            name: this.getRandomItem(this.nameBank),
            city: this.getRandomItem(this.locationBank),
        }));

        this.$testimonials = this.testimonials.map((testimonial) => {
            const testimonialItem = document.createElement('div');
            testimonialItem.className = 'c-take-pledge_testimonial';
            testimonialItem.innerHTML = `
                <span class="c-take-pledge_testimonial-date c-text -body-label-sm">${testimonial.date}</span>
                <p class="c-text -body-sm"><span class="c-take-pledge_testimonial-name">${testimonial.name}</span> - ${testimonial.city}</p>
            `;

            return testimonialItem;
        });

        this.$container.append(...this.$testimonials);
    }

    onScroll() {
        this.POSITIONS = [
            { x: '-50%', y: '0%' },
            { x: '100%', y: '150%' },
            { x: '0%', y: '150%' },
            { x: '100%', y: '10%' },
            { x: '30%', y: '80%' },
            { x: '20%', y: '-50%' },
            { x: '30%', y: '150%' },
            { x: '70%', y: '100%' },
            { x: '50%', y: '120%' },
            { x: '-10%', y: '125%' }
        ]

        if (this.isReducedMotion) {
            gsap.set(this.$testimonials, { opacity: 0 });
            return;
        }

        const progress = this.tlScroll?.progress?.() ?? 0;
        this.tlScroll?.kill?.();
        this.tlScroll = null;

        this.tlScroll = gsap.timeline();

        for (let i = 0; i < this.$testimonials.length; i++) {
            this.tlScroll.from(this.$testimonials[i], {
                top: this.POSITIONS.map((position) => position.y)[i],
                left: this.POSITIONS.map((position) => position.x)[i],
                xPercent: 0,
                yPercent: 0,
            }, 0 * (i * 0.25));
        }

        this.tlScroll.progress(progress);
        this.tlScroll.pause();
    }

    onScrollProgress(value) {
        this.tlScroll?.progress?.(value);
    }

    onSubmit = (event) => {
        event.preventDefault();

        const name = this.$form.querySelector('#name').value;
        const city = this.$form.querySelector('#city').value;
        const formActionUrl = this.$form.getAttribute('action');
        const formMethod = this.$form.getAttribute('method');

        if (!name || !city) {
            return;
        }

        // Reset feedback
        this.$feedback.innerHTML = '&nbsp;';

        fetch(formActionUrl, {
            method: formMethod,
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to submit pledge. Please try again.');
                }
                return response.json();
            })
            .then((data) => {
                this.$feedback.textContent = "Thanks for your commitment to truth, compassion, and dismantling HIV stigma.";
                this.$form.classList.add('-is-submitted');
                this.$number.textContent = (parseInt(this.$number.textContent.replace(/,/g, ""), 10) + 1).toLocaleString();

                const testimonialItem = document.createElement('div');
                testimonialItem.className = 'c-take-pledge_testimonial';
                testimonialItem.dataset.takePledge = 'testimonial';
                testimonialItem.innerHTML = `
                    <span class="c-take-pledge_testimonial-date c-text -body-label-sm">${new Date().toLocaleDateString()}</span>
                    <p class="c-text -body-sm"><span class="c-take-pledge_testimonial-name">${name}</span> - ${city}</p>
                `;

                this.$container.appendChild(testimonialItem);

                const tl = gsap.timeline();
                tl
                    .fromTo(testimonialItem, {
                        top: '50%',
                        left: '50%',
                        xPercent: -50,
                        yPercent: -50,
                    }, {
                        top: '-50%',
                        left: '50%',
                        xPercent: -50,
                        yPercent: 0,
                        duration: 4,
                        ease: 'quart.out'
                    }, 0)
                    .to(testimonialItem, {
                        opacity: 0,
                        duration: 3,
                        ease: 'quart.out'
                    }, 1)

                this.tl.push(tl);

                this.$form.reset();

                setTimeout(() => {
                    this.$form.classList.remove('-is-submitted');
                }, 3000);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    destroyTL() {
        if (!this.tl) return;

        for (let i = 0; i < this.tl.length; i++) {
            this.tl[i].kill();
            this.tl[i] = null;
        }
    }
}
