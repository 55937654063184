import { module } from 'modujs';
// import { CUSTOM_EVENT } from '../config';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.reducedMotionMedia = window.matchMedia("(prefers-reduced-motion: reduce)")

        this.setTimeline();
        this.bindEvents();
    }

    bindEvents() {
        this.reducedMotionMedia?.addEventListener("change", this.onMediaChange)
    }

    unbindEvents() {
        this.reducedMotionMedia?.removeEventListener("change", this.onMediaChange)
    }

    setTimeline() {}

    onMediaChange = () => {
        this.setTimeline();
    }

    onScrollProgress(progress) {
        this.tl?.progress?.(progress)
    }

    destroy() {
        super.destroy();
        this.tl?.kill?.();
    }
}
