import { module } from 'modujs';
import { $html } from '../utils/dom';

export default class extends module {
    constructor(m) {
        super(m);

        this.$closeBtn = this.$('close')[0]
        this.$inner = this.$('inner')[0]
    }

    init() {
        // Prevent ESC to close dialog
        this.onKeyDown = this.onKeyDown.bind(this)
    }

    onKeyDown(e) {
        if(e.key === 'Escape') {
            e.preventDefault()
            this.$closeBtn.click();
        }
    }

    show(args) {
        if(!args) args = {};
        const { preventScrollTop = false } = args;

        this.el.showModal();
        window.addEventListener('keydown', this.onKeyDown);

        // Prevent main scroll
        $html.style.overflow = 'hidden';

        if(!preventScrollTop) {
            requestAnimationFrame(() => {
                this.el.scrollTop = 0;
                this.$inner.scrollTop = 0;
            })
        }
    }

    update() {
        this.el.scrollTop = 0;
        document.getElementById('dialog')?.focus?.()
    }

    close() {
        // Allow main scroll
        $html.style.overflow = '';

        window.removeEventListener('keydown', this.onKeyDown);
        this.el.close();
    }
}
