import Modal from "./Modal";

export default class extends Modal {
    constructor(m) {
        super(m);

        this.$anchors = this.el.querySelectorAll('a[href]')

        this.focusTrapOptions = {
            ...this.focusTrapOptions,
            returnFocusOnDeactivate: false
        }

    }

    bindEvents() {
        super.bindEvents();

        this.$anchors.forEach(anchor => {
            anchor.addEventListener('click', this.onAnchorClick)
        })
    }

    unbindEvents() {
        super.unbindEvents();

        this.$anchors.forEach(anchor => {
            anchor.removeEventListener('click', this.onAnchorClick)
        })
    }

    onAnchorClick = (e) => {
        this.close?.()
    }
}
