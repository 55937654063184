import { module } from 'modujs';
import { gsap } from 'gsap';
import { CUSTOM_EVENT } from '../config';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.compute();
        this.bindEvents();
    }

    bindEvents() {
        window.addEventListener(CUSTOM_EVENT.RESIZE_END, this.compute);
    }

    unbindEvents() {
        window.removeEventListener(CUSTOM_EVENT.RESIZE_END, this.compute);
    }

    compute = () => {
        for(let el of Array.from(this.el.children)) {
            gsap.set(el, { clearProps: 'width' });
            const { width } = el.getBoundingClientRect()
            gsap.set(el, { width: 'max(100vw,'+width+'px)' });
        }
    }

    destroy() {
        super.destroy();

        this.unbindEvents();
    }
}
